import { getHttp } from './fetchApi';

export const Orders = {
    URL: '/orders',

    getOrder(id){
        return getHttp(`${this.URL}/${id}.json`)
    }

}
