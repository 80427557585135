<template>
    <div class="cart cartProducts" :class="{ 'cartClose': $store.getters['general/getCloseCart'] }">
        <!-- <HeaderCart/> -->
        <NavigationCart :position="position" />
        <div class="row m-4">
            <template v-if="$store.getters['cart/getItems']">
                <template v-if="$store.getters['cart/getItems'].length">
                    <div class="col-12 col-sm-6">
                        <h4 class="text-center">{{ $t('cart.list.title') }}</h4>
                        <p class="text-center loading" v-if="!$store.getters['cart/getItems']">{{ $t("common.loading") }}</p>
                        <ProductCart :product="product" v-for="product in $store.getters['cart/getItems']" v-bind:key="product"/>

                        <table class="text-sm cart-details">
                            <tr v-if="$store.getters['cart/get'].totalDiscount === 0">
                                <td colspan="2">
                                    <div class="couponCode">
                                        <div class="form-group">
                                            <input type="text"  v-model="couponCode" id="couponCode" :placeholder="$t('coupon')" />
                                        </div>
                                        <button @click="addCouponCode()">{{ $t('apply') }}</button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="$store.getters['cart/get'].totalDiscount !== 0">
                                <td>{{ $t('coupon') }}:</td>
                                <td>{{ $store.getters['cart/get'].totalDiscountAsCurrency }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('shippingandpackaging') }}:</td>
                                <td>{{ $store.getters['cart/get'].totalShippingCostAsCurrency }}</td>
                            </tr>
                        </table>
                        
                        <h4 class="text-center pt-5">
                            <span v-html="$t('cart.total', {price: $store.getters['cart/get'].totalPriceAsCurrency})"></span>
                            <span class="text-sm font-weight-normal text-family-secondary d-block d-sm-inline mt-3 mt-sm-0">{{ $t('cart.priceinc') }}</span>
                        </h4>
                        <LegalTextsComponent />
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="cart__next">
                            <div class="cart__next-text">{{ $t('cart.toShipping') }}</div>
                            <button @click="$push('shop/cart/shipping')" class="btn-icon-up"></button>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col-12 col-sm-6">
                        <h4 class="text-center">{{ $t('cart.empty') }}</h4>
                    </div>
                </template>
            </template>
            
        </div>
        
    </div>
</template>

<script>
import ProductCart from '@/components/shop/ProductCartComponent'
import NavigationCart from '@/components/shop/NavigationCartComponent'
// import HeaderCart from '@/components/shop/HeaderCart'
import LegalTextsComponent from '@/components/shop/LegalTextsComponent'


export default {
    name: 'Cart',
    components: {
        ProductCart,
        NavigationCart,
        // HeaderCart,
        LegalTextsComponent
    },
    data(){
        return {
            position: 1,
            couponCode: ''
        }
    },
    mounted(){

    },
    methods: {
        async addCouponCode(){
            await this.$store.dispatch('cart/addCouponCode', this.couponCode);
            this.couponCode = '';
        }
    }
}
</script>
