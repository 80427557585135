<template>
    <div class="postPreview__header">
        <h2 class="postPreview__header_title text-family-secondary">
            {{ post.title }}
        </h2>
        <p class="text-uppercase">{{ post.type.name }}</p>
    </div>
    <div class="postPreview__content">
        <div class="row">
            <div class="col-12 col-sm-6 text-family-secondary h2" v-html="post.entryText"></div>
            <div class="col-12 col-sm-6">
                <picture>
                    <img v-if="post.image.entry" :src="post.image.entry" :alt="post.title"/>
                </picture>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'howTo',
    props: ['post'],
    components: {
        
    },
    data(){
        return {}
    },
    mounted(){
    },
    methods: {
    }
}
</script>
