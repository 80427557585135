<template>
  <footer class="mx-5 pt-5" v-if="$store.getters['general/get']">
      <div class="row text-sm mt-sm-5 mb-sm-5 text-family-secondary footer">
          <div class="col-6 col-sm-3" v-html="$store.getters['general/get'].customText1"></div>
          <div class="col-6 col-sm-3" v-html="$store.getters['general/get'].customText2"></div>
          <div class="col-6 col-sm-3">
              <p>Brentano Frey AG<br/>
              Gewerbehaus Lauématt<br/>
              CH-5103 Wildegg</p>
              <p>
                <a href="mailto: info@mybrentano.ch">info@mybrentano.ch</a><br/>
                T 062 893 34 06<br/>
                IG <a href="https://www.instagram.com/mybrentano/" target="blank">@mybrentano</a>
              </p>
              <p>
                <a :href="$url('page/allgemeine-gesch%C3%A4ftsbedingungen')">AGB's</a><br/>
                <a :href="$url('page/datenschutzerklärung')">{{ $t('footer.dataprotection') }}</a><br/>
                <a :href="$url('page/nutzungsbedingungen')">{{ $t('footer.imprint') }}</a><br/>
                <a :href="$url('page/nutzungsbedingungen')">{{ $t('footer.terms') }}</a>
              </p>
              
          </div>
          <div class="col-6 col-sm-3">
              <p>{{ $t('footer.newsletter') }}</p>
              <form @submit="checkForm">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="email" id="email" v-model="email" required />
                </div>
                <button class="btn-icon-right" type="submit"></button>
              </form>
          </div>
      </div>
  </footer>
</template>

<script>
import { Newsletter } from '@/http/Newsletter'

export default {
  name: 'Footer',
  data(){
      return {
          email: ''
      }
  },
  props: {

  },
  methods: {
    checkForm(e) {
        e.preventDefault();
        Newsletter.add(this.email, "testliste");
        this.email = '';
        this.$toast.show(this.$t('thanks'), { duration: 1500 });
    },
  },
}
</script>
