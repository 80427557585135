import { getHttp } from './fetchApi';

export const Posts = {
    URL: '/posts',
    
    get(slug){
        return getHttp(`${this.URL}/${slug}.json`,)
    },

    getAll(page = 0){
        return getHttp(`${this.URL}.json`, { page: page })
    },
    getByCategory(customer, topic, page = 0){
        return getHttp(`${this.URL}.json`, { customer: customer, topic: topic, page: page })
    }
}
