import { postHttp } from './fetchApi';
import { Newsletter } from '@/http/Newsletter' 

export const Address = {

    add(data){
        var formData = new FormData();
        formData.append('action', 'commerce/cart/update-cart');
        Object.entries(data).forEach(([key, value]) => {
            formData.append(`shippingAddress[${key}]`, value);
            formData.append(`billingAddress[${key}]`, value);
        });
        formData.append('email', data.email);
        formData.append('gatewayId', 2);
        if(data.newsletter){
            //send subscription
            Newsletter.add(data.email, "testliste");
        }
        return postHttp('/', formData);
    },
    addInOrder(data){
        var formData = new FormData();
        formData.append('action', 'commerce/cart/update-cart');
        Object.entries(data).forEach(([key, value]) => {
            formData.append(`${key}`, value);
        });
        if(data.newsletter){
            //send subscription
            Newsletter.add(data.email, "testliste");
        }
        return postHttp('/', formData);
    }


}
