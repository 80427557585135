<template>
    <div class="row layoutFullWidth" v-if="$route.meta.layout == 'full'">
        <div class="col">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LayoutFullWidth',
  props: {

  }
}
</script>
