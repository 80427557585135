<template>
  <div class="blog container-col">
      <transition name="slidedown">
          <router-view></router-view>
      </transition>
      <div class="row d-sm-flex" :class="[{ 'd-none': $route.params.section == 'shop' }]">
          <div class="col">
            <keep-alive>
                <Posts />
            </keep-alive>
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Posts from '@/components/blog/PostsComponent'

export default {
    name: 'Blog',
    components: {
        Posts,
    },
    mounted(){
        this.$globalData.setHeaderData({
            title: 'Blog',
            description: '',
        });
        this.$store.dispatch('general/fetch');
    },
    methods: {
        
    }
}
</script>
