<template>
    <div class="productPreview col-sm-6 col-md-4 col-xxl-3">
        <div>
            <div class="productPreview__header">
                <h2 class="productPreview__header_title">
                    <router-link :to="$url(`shop/${product.slug}`)" :title="product.title">{{ product.title }}</router-link>
                </h2>
                <span itemprop="reference" :content="product.ref" class="productPreview__header_reference">{{ product.ref }}</span>
            </div>
            <router-link :to="$url(`shop/${product.slug}`)" class="productPreview__image" :href="$url(`shop/${product.slug}`)" :title="product.title">
                <picture>
                    <img v-if="product.image.url" :src="product.image.url" :alt="product.title"  :width="product.image.width" :height="product.image.height"/>
                    <img v-else src="https://via.placeholder.com/630x420?text=Product+Image" :alt="product.title"/>
                </picture>
            </router-link>
            <div class="productPreview__footer">
                <div class="productPreview__footer__details">
                    <span v-if="product.size" class="productPreview__footer__details__size" itemprop="size" :content="product.size">{{ `${product.size}` }}</span>
                    <span class="productPreview__footer__details__price" itemprop="price" :content="product.price">{{ product.priceAsCurrency }}</span>
                </div>
                <div class="productPreview__footer__details__buy">
                    <ButtonAddCart :product="product" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonAddCart from '@/components/shop/ButtonAddCartComponent'

export default {
    name: 'ProductPreview',
    props: ['product'],
    components: {
        ButtonAddCart
    },
    data(){
        return {}
    },
    mounted(){
    },
    methods: {
    }
}
</script>
