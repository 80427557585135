//import './server/server.js'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClickAway from "vue3-click-away"
import { i18n, defaultLocale } from './i18n/index.js'
import Toaster from '@meforma/vue-toaster';
import VueGtag from "vue-gtag-next";

window.$ = window.jQuery = require('jquery')

export const app = createApp(App)

app.config.globalProperties.$push = function(link) {
    if(defaultLocale == i18n.global.locale){
        router.push(`/${link}`)
    }else{
        router.push(`/${i18n.global.locale}/${link}`)
    }
}



app.config.globalProperties.$redirect = function(link) {
    if(defaultLocale == i18n.global.locale){
        window.location = `/${link}`;
    }else{
        window.location = `/${i18n.global.locale}/${link}`;
    }
}

app.config.globalProperties.$replace = function(link) {
    if(defaultLocale == i18n.global.locale){
        router.replace(`/${link}`)
    }else{
        router.replace(`/${i18n.global.locale}/${link}`)
    }
}

app.config.globalProperties.$url = function(link) {
    if(defaultLocale == i18n.global.locale){
        return`/${link}`
    }else{
        return `/${i18n.global.locale}/${link}`
    }
}

app.config.globalProperties.$goHistory = function(position) {
    console.log(position);
    var history = JSON.parse(localStorage.getItem("history"));
    if(!history){
        this.$push('');
    }else{
        if(history.length > 1){
            this.$push(history.slice(position)[0].substr(1));
        }else{
            this.$push('');
        }
    }
}

app.config.globalProperties.$globalData = {
    createMetaTag(attributes, type) {
        const elementType = type || 'meta'
        let meta = document.createElement(elementType);
        for (var key in attributes) {
            meta.setAttribute(key, attributes[key]);
        }
        document.getElementsByTagName('head')[0].appendChild(meta);
        return meta;
    },
    setHeaderData(data) {
        if (data.title) {
            let regex = /(<([^>]+)>)/ig;
            data.title = data.title.replace(regex, "");
            data.title = `${data.title} - My Brentano`;
            document.title = data.title;
            const ogTitle = document.querySelector('meta[property="og:title"]');
            if (ogTitle !== null) {
                ogTitle.content = data.title;
            }
            const twitterTitle = document.querySelector('meta[name="twitter:title"]');
            if (twitterTitle !== null) {
                twitterTitle.content = data.title;
            }
        }

        const url = window.location.href;
        const canonical = document.querySelector('link[rel="canonical"]') ||  app.config.globalProperties.$globalData.createMetaTag({ rel: 'canonical' }, 'link');
        canonical.href = url;

        const ogUrl = document.querySelector('meta[property="og:url"]') || app.config.globalProperties.$globalData.createMetaTag({ property: 'og:url'});
        ogUrl.content = url;

        if (data.description) {
            const description = (data.description.length > 200 ? data.description.substr(0, 200)+'...' : data.description).replace( /(<([^>]+)>)/ig, ' ');

            const metaDescription = document.querySelector('meta[name="description"]') || app.config.globalProperties.$globalData.createMetaTag({ name: 'description'});
            metaDescription.content = description;

            const ogDescription = document.querySelector('meta[property="og:description"]') || app.config.globalProperties.$globalData.createMetaTag({ property: 'og:description'});
            ogDescription.content = description;

            const twitterDescription = document.querySelector('meta[name="twitter:description"]') || app.config.globalProperties.$globalData.createMetaTag({ name: 'twitter:description'});
            twitterDescription.content = description;
        }
        if (data.image) {
            const ogImage = document.querySelector('meta[property="og:image"]') || app.config.globalProperties.$globalData.createMetaTag({ property: 'og:image'});
            ogImage.content = data.image;

            const twitterImage = document.querySelector('meta[name="twitter:image"]') || app.config.globalProperties.$globalData.createMetaTag({ name: 'twitter:image'});
            twitterImage.content = data.image;
        }
    }
}


app.config.globalProperties.BREAKPOINTS = { 'xs': '576px', 'sm': '768px', 'md': '992px', 'lg': '1200px' };


app
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueClickAway)
    .use(Toaster)
    .use(VueGtag, {
        property: {
            id: "G-VQ2DJKDFQM"
        }
    })
    .mount('#app')
