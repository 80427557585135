<template>
    <div id="shop" class="shop container-col">
        <router-view></router-view>
        <div class="row d-sm-flex px-3">
            <div class="col" >
                <keep-alive>
                    <Products v-if="$route.meta.type === 'category'" :customer="$route.params.customer" :topic="$route.params.topic" />
                </keep-alive>
                <Product :slug="$route.params.slug" v-if="$route.meta.type === 'entry' && $route.params.section == 'shop'" />
                <keep-alive>
                    <Products v-if="!$route.meta.type" />
                </keep-alive>
            </div>
        </div>
        <div class="page container-fluid shop" v-if="page">
            <div class="row block" :class="[block.type]" v-for="block in page.contentBlocks" v-bind:key="block">
            <div :class="[block.className]" v-if="block.type == 'textBlock'" v-html="block.html"></div>
            <div :class="[block.className]" v-if="block.type == 'imageBlock'">
                <img :src="block.image" />
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import Products from '@/components/shop/ProductsComponent'
import Product from '@/components/shop/ProductComponent'
import { Page } from '@/http/Page';


export default {
    name: 'Shop',
    components: {
        Products,
        Product,
    },
    data(){
        return {
            page: null
        }
    },
    activated() {
        
    },
    mounted(){
        this.$globalData.setHeaderData({
            title: 'Shop',
            description: '',
        });
         this.getPage();
        this.$store.dispatch('general/fetch');
    },
    methods: {
        gotoShop(){
            var url = "shop/";
            if(this.$route.params.customer){
                url+=`customer/${this.$route.params.customer}/`;
            }
            if(this.$route.params.topic){
                url+=`thema/${this.$route.params.topic}/`;
            }
            this.$push(url);
            window.scrollTo(0,0);
        },
        async getPage(){
            var response = await Page.get("shop");
            this.page = response.data;
        }

    }
}
</script>
