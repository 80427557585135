import { getHttp, postHttp } from './fetchApi';
import store from '@/store'

export const Cart = {
    URL: '/cart',

    get(){
        return getHttp('?action=commerce/cart/get-cart')
    },

    add(id){
        return postHttp('/', `action=commerce/cart/update-cart&purchasableId=${id}&shippingMethodHandle=freeShipping`);
    },

    update(id, qty){
        var formData = new FormData();
        formData.append('action', 'commerce/cart/update-cart');
        formData.append(`lineItems[${id}][qty]`, qty);
        formData.append('shippingMethodHandle', 'freeShipping');

        return postHttp('/', formData);
    },

    remove(id){
        var formData = new FormData();
        formData.append('action', 'commerce/cart/update-cart');
        formData.append(`lineItems[${id}][remove]`, 1);
        formData.append('shippingMethodHandle', 'freeShipping');

        return postHttp('/', formData);
    },

    addCouponCode(couponCode){
        var formData = new FormData();
        formData.append('action', 'commerce/cart/update-cart');
        formData.append(`couponCode`, couponCode);
        formData.append('shippingMethodHandle', 'freeShipping');

        return postHttp('/', formData);
    }

}
