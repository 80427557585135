<template>
    <div class="postPreview__header">
        <h2 class="postPreview__header_title text-center">
            {{ post.title }}
        </h2>
        <p class="text-uppercase">{{ post.type.name }}</p>
    </div>
    <div class="postPreview__content">
        <div class="postPreview__content__entryText w-50 offset-6 text-family-secondary text-base" v-html="post.entryText"></div>
    </div>
</template>

<script>

export default {
    name: 'bonmot',
    props: ['post'],
    components: {
        
    },
    data(){
        return {}
    },
    mounted(){
    },
    methods: {
    }
}
</script>
