<template>
  <div class="filter" ref="filter">
      <button class="btn-icon-reset" @click="resetFilter()"></button>
      <div>
        <label for="iam">{{ $t("filter.iam") }}</label>
        <div class="filter__field filter__select" :class="{ 'filter__select-show':  showSelectCustomer}" @click="showSelectCustomer = !showSelectCustomer; showSelectTopic = false">
            <select id="iam" v-model="customer" @change="generateURL()">
                <option v-for="option in customers" :key="option" :value="option.slug">{{ option.title }}</option>
            </select>
            <ul>
                <li @click="customer = c.slug; generateURL()" v-for="c in customers" v-bind:key="c">{{ c.title }}</li>
            </ul>
        </div>
      </div>
      <div>
        <label for="interested">{{ $t("filter.interested") }}</label>
        <div class="filter__field filter__select" :class="{ 'filter__select-show':  showSelectTopic}"  @click="showSelectTopic = !showSelectTopic; showSelectCustomer = false">
            <select id="interested" v-model="topic" @change="generateURL()">
                <option v-for="topic in topics" v-bind:key="topic" :value="topic.slug">{{ topic.title }}</option>
            </select>
            <ul>
                <li @click="topic = t.slug; generateURL()" v-for="t in topics" v-bind:key="t">{{ t.title }}</li>
            </ul>
        </div>
      </div>
  </div>
</template>

<script>
import { Customers } from '@/http/Customers'
import { Topics } from '@/http/Topics'

export default {
  name: 'Filter',
  props: {

  },
  data(){
      return {
          customers: [],
          topics: [],
          customer: 'all',
          topic: 'all',
          showSelectCustomer: false,
          showSelectTopic: false
      }
  },
  updated() {
    
  },
  mounted(){
    var _this = this;
    this.getCustomers();
    this.getThemas();
    window.addEventListener('scroll',(event) => {
        if(document.body.getBoundingClientRect().top <= -150){
            _this.$store.commit('general/setShowFilter', false);
        }
    });
      
  },
  methods: {
      resetFilter(){
          this.customer = 'all';
          this.topic = 'all';
          this.$push('shop');
      },
      async getCustomers(){
        this.customers.push({ id: null, title: this.$t('all'), slug: 'all' });
        try{
              var response = await Customers.getAll();
              this.customers.push(...response.data.data);
              this.customer = this.$route.params.customer ? this.$route.params.customer: 'all';
        }catch{/**/}
      },
      async getThemas(){
        this.topics.push({ id: null, title: this.$t('all'), slug: 'all' });
        try{
            var response = await Topics.getAll();
            this.topics.push(...response.data.data);
            this.topic = this.$route.params.topic ? this.$route.params.topic : 'all';
        }catch{/**/}
      },
      generateURL(){
          var url = `${this.$route.params.section}/`;
          if(!this.$route.params.section){
              url = `shop/`;
          }
          if(this.customer != 'all'){
              url+=`customer/${this.customer}/`;
          }
          if(this.topic != 'all'){
              url+=`thema/${this.topic}/`;
          }
          console.log(this.customer);
          this.$push(url);
          
      },
  },
}
</script>
