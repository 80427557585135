<template>
  <div class="cookie-consent" v-if="isShow"> 

      <div v-html="$t('cookies.text')"></div>
    <div class=" ml-2 d-flex align-items-center justify-content-center g-2"> 
        <button class="allow-button mr-1" @click="onShow()">{{ $t('cookies.button') }}</button>
    </div>
</div>
</template>

<script>

export default {
  name: 'Cookies',
  props: {

  },
  data(){
      return {
          isShow: false
      }
  },
  updated() {

  },
  mounted(){
    if(!localStorage.getItem("cookie-consent")){
        this.isShow = true;
    }
  },
  methods: {
      onShow(){
          this.isShow = false;
          localStorage.setItem("cookie-consent", true);
      }
  },
}
</script>
