import { General } from '@/http/General'

export const general = {
    namespaced: true,
    state: {
        currentURL: '/',
        previousCartURL: '',
        isCloseCart: false,
        isShowFilter: false,
        data: null,
    },
    getters: {
        get: state => {
            return state.data
        },
        getCurrentURL: state => {
            return state.currentURL
        },
        getPreviousURL: state => {
            return state.previousURL
        },
        getCloseCart: state => {
            return state.isCloseCart
        },
        getPreviousCartURL: state => {
            return state.previousCartURL
        },
        getShowFilter: state => {
            return state.isShowFilter
        },

    },
    mutations: {
        set: (state, data) => {
            state.data = data;
        },
        setCurrentURL: (state, url) => {
            state.currentURL = url;
        },
        setCloseCart: (state, isCloseCart) => {
            state.isCloseCart = isCloseCart;
        },
        setPreviousCartURL: (state, previousCartURL) => {
            state.previousCartURL = previousCartURL;
        },
        setShowFilter: (state, isShowFilter) => {
            state.isShowFilter = isShowFilter;
        },
    },
    actions: {
        async fetch({ commit }){
            const { data } = await General.get();
            commit('set', data);
        },
    },
}
