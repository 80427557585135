import { getHttp } from './fetchApi';

export const Page = {
    URL: '/pages',
    
    get(slug){
        return getHttp(`${this.URL}/${slug}.json`)
    }

}
