<template>
    <div class="row layoutGrid" v-if="$route.meta.layout == 'grid'">
        <slot/>
    </div>
</template>

<script>
export default {
  name: 'LayoutGrid',
  props: {

  }
}
</script>
