import { getHttp } from './fetchApi';

export const General = {
    URL: '/general',
    
    get(){
        return getHttp(`${this.URL}.json`)
    }

}
