import { getHttp } from './fetchApi';

export const Products = {
    URL: '/products',

    getAll(page = 0, textSearch = ''){
        return getHttp(`${this.URL}.json`, { page: page, s: textSearch })
    },
    getByCategory(customer, topic, page = 0){
        return getHttp(`${this.URL}.json`, { customer: customer, topic: topic, page: page })
    },
    getProduct(slug){
        return getHttp(`${this.URL}/${slug}.json`)
    }

}
