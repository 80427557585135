import { Cart } from '@/http/Cart'

export const cart = {
    namespaced: true,
    state: {
        products: {},
        token: '',
    },
    getters: {
        get: state => {
            return state.products
        },
        getItems: state => {
            return state.products.lineItems
        },
        getQty: state => {
            return state.products.totalQty
        },
        getToken: state => {
            return state.token
        },

    },
    mutations: {
        set: (state, products) => {
            state.products = products;
        },
        setToken: (state, token) => {
            state.token = token;
        },
    },
    actions: {
        async fetch({ commit }){
            const { data } = await Cart.get();
            commit('set', data.cart);
        },
        async add({ commit }, id){
            const { data } = await Cart.add(id);
            commit('set', data.cart);
        },
        async remove({ commit }, id){
            const { data } = await Cart.remove(id);
            commit('set', data.cart);
        },
        async update({ commit }, pdata){
            const { data } = await Cart.update(pdata.id, pdata.qty);
            commit('set', data.cart);
        },
        async addCouponCode({ commit }, couponCode){
            const { data } = await Cart.addCouponCode(couponCode);
            commit('set', data.cart);
        },

    },
}
