<template>
  <header :class="{ 'full': $route.meta.layout == 'full' }">
      <div class="row">
          <div class="col-sm-6 border-right py-2">
              <div class="toolbar">
                  <h1 style="white-space: nowrap;" @click="$push('')">{{ $t("header.hello") }} <span class="text-family-regular">Brentano</span></h1>
                   <div>
                        <ul class="selector__language">
                            <li v-for="locale in $i18n.availableLocales" :class="{'selector__language-active': $i18n.locale === locale}" v-bind:key="locale">
                                <a :lang="locale" :href="locale == defaultLocale ? '/' : `/${locale}`" v-if="$i18n.locale != locale">{{ locale }}</a>
                                <div v-else>{{ locale }}</div>
                            </li>
                        </ul>
                  </div>
              </div>
          </div>
          <div class="col-sm-6 py-2">
              <div class="toolbar">
                <nav>
                    <ul>
                        <li><a :href="$url('shop')"><button class="btn-icon-right"></button><span>Shop</span></a></li>
                        <li><a :href="$url('blog')"><button class="btn-icon-right"></button><span>Blog</span></a></li>
                        <li><a :href="$url('page/about-us')"><button class="btn-icon-right"></button><span>{{ $t("menu.aboutus") }}</span></a></li>
                    </ul>
                </nav>
                <div>
                    <IconCart class="mr-3" />
                    <button @click="onShowSearch" class="btn-icon-search mr-2"></button>
                    <button @click="toggleNavigation()" class="btn-icon-burger d-md-none d-block"></button>
                </div>
              </div>
          </div>
      </div>
      <nav>
            <ul>
                <li><a :href="$url('shop')"><button class="btn-icon-right"></button><span>Shop</span></a></li>
                <li><a :href="$url('blog')"><button class="btn-icon-right"></button><span>Blog</span></a></li>
                <li><a :href="$url('page/about-us')"><button class="btn-icon-right"></button><span>{{ $t("menu.aboutus") }}</span></a></li>
            </ul>
    </nav>
  </header>
  <div id="searcher" v-show="showSearch" @click="onHideSearch">
        <button class="btn-icon-close" @click="showSearch = false"></button>
        <form action="">
            <input ref="textSearch" v-model="textSearch" @keyup="keyupSearch()" aria-autocomplete="both" aria-labelledby="producssearch-label" id="producssearch-input" class="producssearch-input" autocomplete="off" autocorrect="off" autocapitalize="off" enterkeyhint="go" spellcheck="false" :placeholder="$t('form.search')" maxlength="64" type="search">
        </form>
  </div>
</template>

<script>
import { defaultLocale } from '@/i18n';
import enquire from 'enquire.js';
import IconCart from '@/components/shop/IconCartComponent';
import VueClickAway from "vue3-click-away";

export default {
    name: 'Header',
    components: {
        IconCart
    },
    props: {

    },
    data(){
        return {
            defaultLocale: null,
            showAboutUs: false,
            showSearch: false,
            textSearch: '',
        }
    },
    created(){
        var _this = this;
        if(!localStorage.getItem("mobile-filter")){
            localStorage.setItem("mobile-filter", true);
            enquire.register(`screen and (max-width: ${this.BREAKPOINTS.xs})`, {
                match : function() {
                    _this.$store.commit('general/setShowFilter', true);
                },
                unmatch : function() {
                    _this.$store.commit('general/setShowFilter', false);
                }
            });
        }
    },
    mounted(){
        let _this = this;
        this.defaultLocale = defaultLocale;
        document.addEventListener('keydown', function(e) {
            if(e.code == 'Escape'){
                _this.showSearch = false;
            }
        });

        window.$("#app").on('click', function(event){
            if(window.$(event.target).hasClass('btn-icon-search') === false && window.$(event.target).hasClass('producssearch-input') === false){
                console.log("hide Search");
                _this.showSearch = false;
            }
        });
        
    },
    updated(){
        this.showAboutUs = (this.$route.meta.layout == 'full');
    },
    methods: {
        keyupSearch(){
            if(this.textSearch){
                this.$push(`shop/search/${this.textSearch}`);
            }else{
                this.$push('');
            }
        },
        onShowSearch(){
            var _this = this;
            this.showSearch = true;
            setTimeout(function(){
                _this.$refs.textSearch.focus();
            }, 100)
        },
        onHideSearch(event){
            if(event.path[0] == document.getElementById('searcher')){
                this.showSearch = false;
            }
        },
        toggleNavigation(){
            window.$("body").toggleClass('show-navigation');
        },
        openFilter(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        showFilter(){
            if(this.$store.getters['general/getShowFilter']){
                this.$store.commit('general/setShowFilter', false);
            }else{
                this.$store.commit('general/setShowFilter', true);
            }
            if(this.$route.name == "Page"){
                this.$push('');
            }
        },
        openAboutUs(){
            this.showAboutUs = this.showAboutUs ? false : true;
            if(this.showAboutUs){
                if(this.$store.getters['general/getShowFilter']){
                    this.$store.commit('general/setShowFilter', false);
                }
                this.$push('page/about-us');
            }else{
                this.$router.back();
            }
        }
    }
}
</script>
