<template>
    <div class="newsletter-container" v-if="isShow"> 
        <div class="newsletter-box">
            <button @click="onShow();" class="btn-icon-close"></button>
            <p>Willst du immer auf dem neusten Stand sein? Melde dich für unseren Newsletter an.</p>
            <form @submit="checkForm">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" id="email" v-model="email" required />
                </div>
                <button class="btn-icon-right" type="submit"></button>
            </form>
        </div>
    </div>
</template>

<script>
import { Newsletter } from '@/http/Newsletter'

export default {
  name: 'Newsletter',
  props: {

  },
  data(){
      return {
          email: '',
          isShow: false
      }
  },
  updated() {

  },
  mounted(){
    if(!localStorage.getItem("newsletter-box") && localStorage.getItem("firstTime")){
        this.isShow = true;
    }
    localStorage.setItem("firstTime", true);
  },
  methods: {
    onShow(){
        this.isShow = false;
        localStorage.setItem("newsletter-box", true);
    },
    checkForm(e) {
        e.preventDefault();
        Newsletter.add(this.email, "testliste");
        this.email = '';
        this.$toast.show(this.$t('thanks'), { duration: 1500 });
        this.onShow();
    },
  },
}
</script>
