<template>
    <div class="col-md-6">
        <div class="postOverview w-100 px-2 py-5">
            <div>        
                <p class="type">{{ post.type.name }}</p>
                <h2 class="mb-3">
                    <a :href="$url(`blog/${post.slug}`)" v-html="post.title"  :title="post.title"></a>
                </h2>
                <button class="btn-icon-right" @click="$push(`blog/${post.slug}`)"></button>
            </div>
            <div v-if="post.image">
                <a :href="$url(`blog/${post.slug}`)" :title="post.title">
                    <img :src="post.image.entry" :alt="post.title" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'PostPreview',
    props: ['post'],
    components: {
    },
    data(){
        return {}
    },
    mounted(){
    },
    methods: {
    }
}
</script>
