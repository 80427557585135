import { createRouter, createWebHistory } from 'vue-router'
//import Root from "./Root"
import { i18n, defaultLocale } from '../i18n'

//views
import Blog from '@/views/Blog'
import Post from '@/views/Blog/Post'
import Shop from '@/views/Shop'
import Cart from '@/views/Shop/Cart'
import CartShipping from '@/views/Shop/CartShipping'
import CartPayment from '@/views/Shop/CartPayment'
import CartCompleted from '@/views/Shop/CartCompleted'
import CartError from '@/views/Shop/CartError'
import Page from '@/views/Page'
import Home from '@/views/Home'
import NotFound from '@/views/NotFound'

const routes = [
    {
        path: '/:locale(en|de|fr|it)?',
        name: 'Home',
        components: {
            content: Home
        },
        meta: {
            layout: 'grid',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/shop',
        name: 'Shop',
        components: {
            content: Shop
        },
        meta: {
            layout: 'grid',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/blog',
        name: 'Entries',
        components: {
            content: Blog
        },
        meta: {
            layout: 'grid',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/blog/:slug',
        name: 'EntryBlog',
        components: {
            content: Post
        },
        meta: {
            layout: 'grid',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/search/:search',
        name: 'Search',
        components: {
            content: Shop
        },
        meta: {
            layout: 'grid',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/cart',
        name: 'Cart',
        components: {
            content: Cart
        },
        meta: {
            layout: 'grid',
            history: false
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/cart/shipping',
        name: 'CartShipping',
        components: {
            content: CartShipping
        },
        meta: {
            layout: 'grid',
            history: false
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/cart/payment',
        name: 'CartPayment',
        components: {
            content: CartPayment
        },
        meta: {
            layout: 'grid',
            history: false
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/cart/completed/:orderId',
        name: 'CartCompleted',
        components: {
            content: CartCompleted
        },
        meta: {
            layout: 'grid',
            history: false
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/cart/error',
        name: 'CartError',
        components: {
            content: CartError
        },
        meta: {
            layout: 'grid',
            history: false
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/blog/customer/:customer',
        name: 'EntriesTag',
        components: {
            content: Blog
        },
        meta: {
            layout: 'grid',
            type: 'category',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/customer/:customer',
        name: 'EntriesTag',
        components: {
            content: Shop
        },
        meta: {
            layout: 'grid',
            type: 'category',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/blog/thema/:topic',
        name: 'EntriesCategory',
        components: {
            content: Blog
        },
        meta: {
            layout: 'grid',
            type: 'category',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/thema/:topic',
        name: 'EntriesCategory',
        components: {
            content: Shop
        },
        meta: {
            layout: 'grid',
            type: 'category',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/blog/customer/:customer/thema/:topic',
        name: 'EntriesTagCategory',
        components: {
            content: Blog
        },
        meta: {
            layout: 'grid',
            type: 'category',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/customer/:customer/thema/:topic',
        name: 'EntriesTagCategory',
        components: {
            content: Shop
        },
        meta: {
            layout: 'grid',
            type: 'category',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/:section(shop)/:slug',
        name: 'Entry',
        components: {
            content: Shop
        },
        meta: {
            layout: 'grid',
            type: 'entry',
            history: true
        }
    },
    {
        path: '/:locale(en|de|fr|it)?/page/:slug',
        name: 'Page',
        components: {
            content: Page
        },
        meta: {
            layout: 'grid',
            history: true
        }
    },
    
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        components: {
            full: NotFound
        },
        meta: {
            layout: 'full',
            history: true
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    mode: 'history',
})

router.beforeEach((to, from , next) => {
    if (to.params.locale === from.params.locale) {
        next()
        return
    }

    const lang = to.params.locale || defaultLocale
    i18n.global.locale = lang

    next();
});

router.afterEach(to => {
    if(to.meta.history){
        var history = JSON.parse(localStorage.getItem("history"));
        if(!history || to.path == "/"){
            history = [];
            localStorage.removeItem("history");
        }
        if(history[ history.length - 1 ] != to.path){
            history.push(to.path);
        }
        localStorage.setItem("history", JSON.stringify(history));
    }
});

export default router
