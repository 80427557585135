<template>
  <div class="page container-fluid" :class="[$route.params.slug]" v-if="page">
    <div class="row block" :class="[block.type]" v-for="block in page.contentBlocks" v-bind:key="block">
      <div :class="[block.className]" v-if="block.type == 'textBlock'" v-html="block.html"></div>
      <div :class="[block.className]" v-if="block.type == 'imageBlock'">
        <img :src="block.image" />
      </div>
    </div>
  </div>
  <div v-else>
    <p class="loading">{{ $t("common.loading") }}</p>
  </div>
</template>

<script>
import { Page } from '@/http/Page';

export default {
  name: 'Page',
  components: {
  },
  data(){
    return {
      page: null
    }
  },
  mounted(){
    this.getPage();
    this.$store.dispatch('general/fetch');
  },
  methods: {
    async getPage(){
      try{
        var response = await Page.get(this.$route.params.slug);
        this.page = response.data;
        this.$globalData.setHeaderData({
            title: this.page.title,
            description: this.page.metaDescription,
        });
      }catch{/**/}
    }
  }
}
</script>