<template>
    <div>
        <Filter />
        <template v-if="showAll">
            <div class="row products">
                <ProductPreview :product="product" v-for="product in products" v-bind:key="product" />
            </div>
        </template>
        <template v-else>
            <div class="mt-5" v-for="category in getProductsByCategory()" v-bind:key="category">
                <div class="p-3 text-family-secondary">
                    <h3 class="text-lg mb-2">{{ category.details.title }} - {{ category.details.subtitle }}</h3>
                    <div v-html="category.details.entryText"></div>
                </div>
                <div class="row products">
                    <ProductPreview :product="product" v-for="product in category.products" v-bind:key="product" />
                </div>
            </div>
        </template>
        <p class="text-center loading" v-if="loading">{{ $t("common.loading") }}</p>
        <div class="text-center" v-if="loading == false & products.length == 0">
            <p>{{ $t("filter.notfound") }}</p>
        </div>
    </div>
</template>

<script>
import { Products } from '@/http/Products';
import ProductPreview from '@/components/shop/ProductPreviewComponent'
import Filter from '@/components/Filter'

export default {
    name: 'Products',
    props: [ 'customer', 'topic' ],
    components: {
        ProductPreview,
        Filter
    },
    data(){
        return {
            products: [],
            page: 1,
            loading: false,
            finished: false,
            time: null,
            topic_data: '',
            customer_data: '',
            showAll: false,
        }
    },
    watch: {
        '$route.params': {
            handler(newValue) {
                if(newValue['section']){
                   this.showAll = true; 
                }else{
                    this.showAll = false;
                }
                console.log(newValue);
                this.topic_data = newValue.topic;
                this.customer_data = newValue.customer;
                this.page = 0;
                this.products = [];
                this.getProducts();
            }
        },
    },
    computed: {
        currentURL: {
            get(){
                return this.$store.getters['general/getCurrentURL'];
            }
        }
    },
    mounted(){
        if(this.$route.params['section']){
            this.showAll = true; 
        }else{
            this.showAll = false;
        }
        var _this = this;
        this.topic_data = this.topic;
        this.customer_data = this.customer;
        this.getProducts();
        window.$(document).on('keyup', '.filter #s', function(event){
            clearTimeout(_this.time);
            _this.time = setTimeout(function(){
                _this.reset();
                _this.getProducts();
            }, 600);
        });
    },
    unmounted() {
        
    },
    methods: {
        handleScroll: function(e){
            try{
                let element = document.getElementById('shop');
                if ((element.offsetHeight + e.target.scrollTop) >= element.getElementsByClassName('products')[0].offsetHeight-200 && !this.loading && !this.finished){
                    this.getProducts();
                }
            }catch(error){
                //console.log(error);
            }
        },
        async getProducts(){
            try{
                this.loading = true;
                var response;
                if(this.customer_data || this.topic_data){
                    response = await Products.getByCategory(this.customer_data, this.topic_data, this.page);
                }else{
                    if(this.$route.params.search){
                        response = await Products.getAll(this.page, this.$route.params.search);
                    }else{
                        response = await Products.getAll(this.page);
                    }
                }
                if(response.data.meta.pagination.current_page == response.data.meta.pagination.total_pages){
                    this.finished = true;
                }
                this.products = this.products.concat(response.data.data);

                this.page++;
            }catch{/**/}
            this.loading = false;
        },
        getProductsByCategory(){
            var productsByCategory = {};
            this.products.map((product, index) => {
                if(product.productCategory.length){
                    if(!productsByCategory[product.productCategory[0].slug]){
                        productsByCategory[product.productCategory[0].slug] = { details: null, products: [] };
                    }
                    productsByCategory[product.productCategory[0].slug]['details'] = product.productCategory[0];
                    productsByCategory[product.productCategory[0].slug]['products'].push(product);
                }
            });
            return productsByCategory;
        },
        reset(){
            this.products = [];
            this.page = 0;
        }
    }
}
</script>
