<template>
    <div class="cart cartShipping" :class="{ 'cartClose': $store.getters['general/getCloseCart'] }">
        <!-- <HeaderCart/> -->
        <NavigationCart :position="position" />
        <div class="row m-4">
            <div class="col-12 col-sm-6">
                <h4 class="text-center">{{ $t('cart.shipping.title') }}</h4>
                <form @submit="checkForm">
                  <div class="form-group">
                    <label for="title">{{ $t('form.Salutation') }}</label>
                    <select v-model="address.title" id="title" class="">
                      <option :value="$t('form.family')">{{ $t('form.family') }}</option>
                      <option :value="$t('form.woman')">{{ $t('form.woman') }}</option>
                      <option :value="$t('form.man')">{{ $t('form.man') }}</option>
                      <option :value="$t('form.Madame')">{{ $t('form.Madame') }}</option>
                      <option :value="$t('form.Mr')">{{ $t('form.Mr') }}</option>
                      <option :value="$t('form.Mrs')">{{ $t('form.Mrs') }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="firstName">{{ $t('form.firstname') }}</label>
                    <input type="text"  v-model="address.firstName" id="firstName" required />
                  </div>
                  <div class="form-group">
                    <label for="lastName">{{ $t('form.lastname') }}</label>
                    <input type="text" v-model="address.lastName" id="lastName" required />
                  </div>
                  <div class="form-group">
                    <label for="address1">{{ $t('form.Address') }}</label>
                    <input type="text" v-model="address.addressLine1" id="address1" required />
                  </div>
                  <div class="form-group">
                    <label for="city">{{ $t('form.city') }}</label>
                    <input type="text" v-model="address.locality" id="city" required />
                  </div>
                  <div class="form-group">
                    <label for="zipCode">{{ $t('form.zip') }}</label>
                    <input type="text" v-model="address.postalCode" id="zipCode" required />
                  </div>
                  <div class="form-group">
                    <label for="email">{{ $t('form.Email') }}</label>
                    <input type="email" v-model="address.email" id="email" required />
                  </div>
                  <div class="form-group">
                    <label for="phone">{{ $t('form.phone') }}</label>
                    <input type="text" v-model="address.phone" id="phone" required />
                  </div>
                  <div class="form-group mb-1">
                    <label for="notes">{{ $t('form.notes') }}</label>
                    <textarea v-model="address.notes" id="notes"></textarea>
                  </div>
                  <button class="text-hide" ref="buttonSubmit" type="submit"></button>
                  
                  <input type="checkbox" v-model="order.newsletter" class="d-none">
                  
                  <input type="checkbox" v-model="order.agb" style="opacity: 0" required>
                  <div class="text-sm d-flex align-items-center mb-3">
                    <button type="button" :class="{ 'btn-icon-check': order.agb, 'btn-icon-uncheck': !order.agb }" @click="order.agb = !order.agb"></button><span class="ml-3 text-family-secondary">{{ $t('form.agb') }}</span>
                  </div>
                  <div class="text-sm d-flex align-items-center mb-5">
                    <button type="button" :class="{ 'btn-icon-check': order.newsletter, 'btn-icon-uncheck': !order.newsletter }" @click="order.newsletter = !order.newsletter"></button><span class="ml-3 text-family-secondary">{{ $t('form.newsletter') }}</span>
                  </div>

                </form>
                

                <LegalTextsComponent />
                
            </div>
            <div class="col-12 col-sm-6">
              <div class="cart__next">
                <div class="cart__next-text">{{ $t('cart.toPayment') }}</div>
                <button @click="$refs.buttonSubmit.click()" class="btn-icon-up"></button>
              </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import NavigationCart from '@/components/shop/NavigationCartComponent'
import { Address } from '@/http/Address'
// import HeaderCart from '@/components/shop/HeaderCart'
import LegalTextsComponent from '@/components/shop/LegalTextsComponent'

export default {
    name: 'CartShipping',
    components: {
        NavigationCart,
        // HeaderCart,
        LegalTextsComponent
    },
    data(){
        return {
          position: 2,
          errors: [],
          address: {
            countryCode: "CH",
            title: null,
            firstName: null,
            lastName: null,
            fullName: null,
            addressLine1: null,
            locality: null,
            notes: null,
            postalCode: null,
            phone: null,
            makePrimaryBillingAddress: true,
            makePrimaryShippingAddress: true,
            administrativeArea: "-",
            email: null,
          },
          order: {
            newsletter: false,
            agb: false,
            gatewayId: 2,
            shippingAddressId: null,
            billingAddressId: null,
            email: null
          }
        }
    },
    mounted(){
      this.address = this.$store.getters['address/get']['address'];
      this.order = this.$store.getters['address/get']['order'];
    },
    methods: {
      checkForm(e) {
        e.preventDefault();
        this.addAddress();
      },
      async addAddress(){
        this.address['administrativeArea'] = this.address['locality'];
        this.address['countryCode'] = "CH";
        this.address['newsletter'] = this.order.newsletter;
        this.$store.commit('address/set', {
          address: this.address,
          order: this.order
        });

        this.address.fullName = `${this.address.firstName} ${this.address.lastName}`;

        var response = await Address.add(this.address);
        /*console.log(response);
        this.order.shippingAddressId = response.data.cart.shippingAddress.id;
        this.order.billingAddressId = response.data.cart.billingAddress.id;*/
        this.$push('shop/cart/payment');
        //this.updateOrder();
      },
      async updateOrder(){
        var response = await Address.addInOrder(this.order);
        if(response.data.success){
          this.$push('shop/cart/payment');
        }
      }
    }
}
</script>
