import { createStore } from 'vuex'

//modules
import { cart } from './cart'
import { general } from './general'
import { address } from './address'

export default createStore({
  modules: {
      cart: cart,
      general: general,
      address: address
  }
})
