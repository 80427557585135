<template>
    <div class="container-fluid">
        <Header/>
        <LayoutFullWidth>
            <router-view name="full"></router-view>
        </LayoutFullWidth>

        <LayoutGrid>
            <div class="col-12 pt-0 px-0 order-md-0">
                <router-view name="content"></router-view>
            </div>
        </LayoutGrid>
        <Newsletter />
        <Cookies />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header'
import LayoutFullWidth from '@/components/layouts/LayoutFullWidth'
import LayoutGrid from '@/components/layouts/LayoutGrid'
import Cookies from '@/components/Cookies'
import Newsletter from '@/components/Newsletter'
import Footer from '@/components/Footer';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex'

export default {
    name: 'App',
    components: {
        Header,
        LayoutFullWidth,
        LayoutGrid,
        Cookies,
        Newsletter,
        Footer
    },
    data(){
        return {
            section: null,
        }
    },
    /*setup() {
        const { t, locale } = useI18n();
        const store = useStore();

        watch(locale, (newValue) => {
            // Your dispatch actions
            store.dispatch('general/fetch');
        });

        return {};
    },*/
    mounted(){

        this.$store.dispatch('cart/fetch');



        window.addEventListener('scroll', this.handleScroll);

        this.$globalData.setHeaderData({
            title: 'Home',
        });

    },
    methods: {
        isShop(){
            if(this.section && this.$route.params.section){
                return (this.section == 'blog');    
            }else{
                return (this.$route.params.section == 'blog');
            }
        },
        isBlog(){
            if(this.section && this.$route.params.section){
                return (this.section == 'shop');    
            }else{
                return (this.$route.params.section == 'shop');
            }
        },
        handleScroll: function(){
            var element = document.getElementsByClassName("container-col");
            if(this.$route.params.section == 'shop'){
                element = element[1];
            }else{
                element = element[0];
            }
            
            if(element){
                var position = element.getBoundingClientRect();
                if(position.top <= 0){
                    //do fixed
                    let section = (this.$route.params.section) ? this.$route.params.section : 'blog';
                    // window.$("body").addClass(`subtitle-fixed subtitle-fixed-${section}`);
                }else{
                    //do not fixed
                    // window.$("body").removeClass(`subtitle-fixed subtitle-fixed-blog subtitle-fixed-shop`);
                }

            }
        }
    }
}
</script>

<style lang="scss">
@import "./assets/sass/app.scss";
</style>
