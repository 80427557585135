<template>
    <div class="navigationCart">
        <ul>
            <li :class="{ 'step': position >= 1, 'step-active': position == 1 }">
                <router-link v-if="position+1 >= 1 && position < 4" :to="$url(`shop/cart`)">{{$t("cart.shopping")}}</router-link>
                <span v-else>{{$t("cart.shopping")}}</span>
            </li>
            <li :class="{ 'step': position >= 2, 'step-active': position == 2 }">
                <router-link v-if="position+1 >= 2 && position < 4" :to="$url(`shop/cart/shipping`)">{{$t("cart.shipping")}}</router-link>
                <span v-else>{{$t("cart.shipping")}}</span>
            </li>
            <li :class="{ 'step': position >= 3, 'step-active': position == 3 }">
                <span>{{$t("cart.payment")}}</span>
            </li>
            <li :class="{ 'step': position >= 4 }">
                <span>{{$t("cart.completed")}}</span>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'NavigationCart',
    props: {
        position:{
            type: Number,
            default: 1
        }
    },
    computed: {
    },
    data(){
        return {}
    },
    mounted(){

    },
    methods: {
    }
}
</script>
