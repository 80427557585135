<template>
  <div class="page container-fluid px-5 home" v-if="page">
        <div class="row block" :class="[block.type]" v-for="block in page.contentBlocks" v-bind:key="block">
        <div :class="[block.className]" v-if="block.type == 'textBlock'" v-html="block.html"></div>
        <div :class="[block.className]" v-if="block.type == 'imageBlock'">
            <img :src="block.image" />
        </div>
        </div>
        <div class="productsRelated" v-if="page.productsRelated.length">
            <div class="text-center text-base mb-3" v-html="page.titleProductsRelated"></div>
            <div class="row justify-content-center">
                <ProductPreview :product="product" v-for="product in page.productsRelated" v-bind:key="product" />
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { Page } from '@/http/Page';
import { Products } from '@/http/Products';
import ProductPreview from '@/components/shop/ProductPreviewComponent'

export default {
  name: 'Home',
  data(){
      return {
          page: null,
      }
  },
  components: {
    ProductPreview
  },
  mounted(){
    this.getPage();
      this.$store.dispatch('general/fetch');
  },
  methods: {
    async getPage(){
        var response = await Page.get("__home__");
        this.page = response.data;
    }
  },
}
</script>
