<template>
    <div class="postPreview__header offset-1">
        <h2 class="postPreview__header_title text-uppercase" v-html="post.title"></h2>
        <p class="text-uppercase">{{ post.type.name }}</p>
    </div>
    <div class="postPreview__content">
        <h3 class="offset-1 h2 w-75 mb-5">{{ post.subtitle }}</h3>
        <picture>
            <img v-if="post.image.entry" :src="post.image.entry" :alt="post.title"/>
        </picture>
        <div class="postPreview__content__entryText w-100 w-sm-50 text-base text-family-secondary" v-html="post.entryText"></div>
    </div>
</template>

<script>

export default {
    name: 'dictionary',
    props: ['post'],
    components: {
        
    },
    data(){
        return {}
    },
    mounted(){
    },
    methods: {
    }
}
</script>
