import axios from 'axios';
import { useI18n } from 'vue-i18n'

const BASE_URL = process.env.VUE_APP_API_URL;


export default async (url, method, options = {}) => axios ({
    method: method.toUpperCase(),
    url: `${getBaseUrl()}${url}`,
    withCredentials: true,
    ...options,
});

export const getBaseUrl = () => {
    try {
        const i18n = useI18n();
        if (i18n.locale.value != 'de') {
            return `${BASE_URL}/${i18n.locale.value}`;
        }
        return BASE_URL;
    } catch (e) {
        return BASE_URL;
    }
};
