<template>
    <div class="postPreview__header d-sm-flex">
        <h2 class="postPreview__header_title mr-5">
            {{ post.title }}
        </h2>
        <h3 class="postPreview__header_subtitle offset-6 offset-sm-0">
            {{ post.subtitle }}
        </h3>
        <p class="text-uppercase">{{ post.type.name }}</p>
    </div>
    <div class="postPreview__content">
        <picture>
            <img v-if="post.image.entry" :src="post.image.entry" :alt="post.title"/>
        </picture>
        <div class="postPreview__content__entryText h2" v-html="post.entryText"></div>
        <div class="offset-6 mt-5 text-family-secondary text-base mb-5" v-html="post.sender"></div>
    </div>
</template>

<script>

export default {
    name: 'faq',
    props: ['post'],
    components: {
        
    },
    data(){
        return {}
    },
    mounted(){
    },
    methods: {
    }
}
</script>
