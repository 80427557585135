<template>
    <div class="iconCart">
        <button @click="gotoCart()">
            <span>{{ $store.getters['cart/getQty'] }}</span>
        </button>
    </div>
</template>

<script>

export default {
    name: 'IconCart',
    props: [],
    computed: {
    },
    data(){
        return {}
    },
    mounted(){

    },
    methods: {
        gotoCart(){
            this.$store.commit('general/setPreviousCartURL', window.location.pathname.substring(1));
            this.$push('shop/cart');
        }
    }
}
</script>
