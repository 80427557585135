<template>
    <div class="postPreview__header">
        <p class="text-uppercase">{{ post.type.name }}</p>
    </div>
    <div class="postPreview__content">
        <div class="postPreview__content__entryText w-50 w-sm-25 mb-mb-5 offset-6 offset-sm-7 text-family-secondary text-base" v-html="post.entryText"></div>
        <picture>
            <img v-if="post.image.entry" :src="post.image.entry" :alt="post.title"/>
        </picture>
        <div class="questions mb-5 mb-sm-0" v-for="question in post.question" v-bind:key="question">
            <div class="row mb-3 mb-sm-0">
                <div class="col-10 col-sm-5 text-lg">
                    {{ question.title }}
                </div>
            </div>
            <div class="row">
                <div class="col-10 offset-2 offset-sm-0 col-sm-7 offset-sm-5 text-lg">
                    {{ question.answer }}
                </div>
            </div>
        </div>
        <div class="postPreview__content__entryText w-50 w-sm-25 mb-mb-5 mt-5 offset-6 offset-sm-7 text-family-secondary text-base" v-html="post.title"></div>
    </div>
</template>

<script>

export default {
    name: 'interview',
    props: ['post'],
    components: {
        
    },
    data(){
        return {}
    },
    mounted(){
    },
    methods: {
    }
}
</script>
