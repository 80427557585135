import baseHttp from './baseHttp';

export const getHttp = async (
    url,
    params,
    options,
) => baseHttp(url,
    'get',
    {
        ...options,
        params
    });

export const postHttp = async (
        url,
        data,
        options,
    ) => baseHttp(url,
        'post',
        {
            data,
            ...options
        });
