<template>
  <div class="not-found">
    <div class="row">
      <div class="col-12">
        <p class="font-weight-bold h2 text-center my-5">{{ $t('notfound.title') }}</p>
        <p class="font-weight-bold h3 text-center my-5">{{ $t('notfound.description') }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  components: {
    
  },
  data(){
    return {
      
    }
  },
  mounted(){
  },
  methods: {
    
  }
}
</script>