<template>
    <div class="postPreview__header">
        <h2 class="postPreview__header_title text-family-secondary text-lg">
            {{ post.title }}
        </h2>
        <p class="text-uppercase">{{ post.type.name }}</p>
    </div>
    <div class="postPreview__content">
        <ul class="postPreview__content__facts">
            <li v-for="(fact, index) in post.fact" v-bind:key="fact">
                <span class="text-family-secondary">{{ index+1 }}</span>
                <div v-html="fact.entryText"></div>
            </li>
        </ul>
        <picture>
            <img v-if="post.image.entry" :src="post.image.entry" :alt="post.title"/>
        </picture>
    </div>
</template>

<script>

export default {
    name: 'facts',
    props: ['post'],
    components: {
        
    },
    data(){
        return {}
    },
    mounted(){
    },
    methods: {
    }
}
</script>
