<template>
    <div class="cart cartCompleted" :class="{ 'cartClose': $store.getters['general/getCloseCart'] }">
        <!-- <HeaderCart/> -->
        <NavigationCart :position="position" />
        <div class="row m-4">
            <div class="col-12 col-sm-6">
              <h4 class="text-center">{{ $t('cart.completed.title') }}</h4>
              <template v-if="order">
                <ProductCart :product="product" v-for="product in order.lineItems" v-bind:key="product"/>
              </template>
              <div class="loading" v-else>{{ $t("common.loading") }}</div>
              <h4 class="text-center" style="margin-top: 10rem">{{ $t('cart.completed.shipping.title') }}</h4>
              <div class="cart__address" v-if="order">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <ul>
                      <li>{{order.shippingAddress.name}}</li>
                      <li>{{order.shippingAddress.address1}}</li>
                      <li>{{order.shippingAddress.city}}</li>
                      <li>{{order.shippingAddress.zipCode}}</li>
                      <li>{{order.shippingAddress.countryText}}</li>
                    </ul>
                  </div>
                  <div class="col-12 col-sm-6">
                    <ul>
                      <li>{{order.email}}</li>
                      <li v-if="order.shippingAddress.phone">{{order.shippingAddress.phone}}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="loading" v-else>{{ $t("common.loading") }}</div>
              <LegalTextsComponent />
            </div>
            <div class="col-12 col-sm-6">
                <div class="cart__next">
                    <div class="cart__next-text">{{ $t('cart.toHome') }}</div>
                    <button @click="$push('')" class="btn-icon-up mt-4"></button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import ProductCart from '@/components/shop/ProductCartComponent'
import NavigationCart from '@/components/shop/NavigationCartComponent'
import { Orders } from '@/http/Orders'
// import HeaderCart from '@/components/shop/HeaderCart'
import LegalTextsComponent from '@/components/shop/LegalTextsComponent'
import { useGtag } from "vue-gtag-next";

export default {
    name: 'CartCompleted',
    components: {
        ProductCart,
        NavigationCart,
        // HeaderCart,
        LegalTextsComponent
    },
    data(){
        return {
            position: 4,
            order: null
        }
    },
    mounted(){
        this.get();
    },
    methods: {
      async get(){
        var response = await Orders.getOrder(this.$route.params.orderId);
        this.order = response.data;
        this.trackConversion();
      },
      trackConversion(){
        
        // Access gtag from the window object
        //if (typeof window.gtag === 'function') {
          const gtagData = {
            //transaction_id: this.order.id,
            //affiliation: 'Brentano',
            value: this.order.totalPaid,
            currency: this.order.currency,
            /*shipping: this.order.totalShippingCost,
            tax: this.order.totalTax,
            coupon: this.order.couponCode,
            items: this.order.lineItems.map((item) => {
              return {
                id: item.id,
                name: item.description,
                quantity: item.qty,
                price: item.price
              }
            })*/
          };
          //send to all
          //window.gtag('event', 'conversion', gtagData);

          //send to ad words
          gtagData.send_to = 'AW-302664101/vT_hCKmcl_EYEKWTqZAB';

          const { event } = useGtag()
          event('conversion', gtagData)

          console.log(gtagData);

          /*window.gtag('event', 'conversion', gtagData);

          console.info('Google Analytics (gtag) event sent.');

        } else {
          console.error('Google Analytics (gtag) is not defined.');
        }*/
      }
    }
}
</script>
