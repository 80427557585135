<template>
    <div>
        <div class="row products m-3">
            <PostPreview :post="post" v-for="post in posts" v-bind:key="post" />
        </div>
        <!-- <p class="text-center loading" v-if="loading">{{ $t("common.loading") }}</p> -->
    </div>
</template>

<script>
import { Posts } from '@/http/Posts';
import PostPreview from '@/components/blog/PostPreviewComponent'

export default {
    name: 'Posts',
    props: [ 'customer', 'topic' ],
    components: {
        PostPreview
    },
    data(){
        return {
            posts: [],
            loading: false,
            finished: false
        }
    },
    computed: {
        currentURL: {
            get(){
                return this.$store.getters['general/getCurrentURL'];
            }
        }
    },
    created(){
        this.getPosts();
    },
    unmounted: function () {

    },
    methods: {
        async getPosts(){
            try{
                this.loading = true;
                var response;
                if(this.customer || this.topic){
                    response = await Posts.getByCategory(this.customer, this.topic, this.page);
                }else{
                    response = await Posts.getAll(this.page);
                }
                this.posts = response.data.data;
            }catch{/**/}
            this.loading = false;
        }
    },
    watch: {
        currentURL(newValue, oldValue){
            this.posts = [];
            this.getPosts();
        }
    }
}
</script>
