<template>
    <div class="cart cartError" :class="{ 'cartClose': $store.getters['general/getCloseCart'] }">
        <!-- <HeaderCart/> -->
        <NavigationCart position="0" />
        <div class="row m-4">
            <div class="col-12 col-sm-6">
              <h4 class="text-center">{{ $t('cart.error.title') }}</h4>             
            </div>
            <div class="col-12 col-sm-6">
                <div class="cart__next">
                    <div class="cart__next-text">{{ $t('cart.toCart') }}</div>
                    <button @click="$push('shop/cart')" class="btn-icon-up"></button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import NavigationCart from '@/components/shop/NavigationCartComponent'
// import HeaderCart from '@/components/shop/HeaderCart'

export default {
    name: 'CartError',
    components: {
        NavigationCart,
        // HeaderCart
    },
    data(){
        return {
            position: 4
        }
    },
    mounted(){

    },
    methods: {

    }
}
</script>
