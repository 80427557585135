<template>
    <div class="cart cartPayment" :class="{ 'cartClose': $store.getters['general/getCloseCart'] }">
        <!-- <HeaderCart/> -->
        <NavigationCart :position="position" />
        <div class="row m-4">
            <div class="col-12 col-sm-6 text-center">
                <h4 class="text-center">{{ $t('cart.payment.title') }}</h4>
                <p class="loading" v-if="this.loading">{{ $t("common.loading") }}</p>
                <LegalTextsComponent />
                <!--<button class="btn btn-outline-primary" @click="openPay" :disabled="loading">Open Payment Method</button>-->
            </div>
        </div>
        
    </div>
</template>

<script>
import NavigationCart from '@/components/shop/NavigationCartComponent'
import { Payment } from '@/http/Payment'
// import HeaderCart from '@/components/shop/HeaderCart'
import LegalTextsComponent from '@/components/shop/LegalTextsComponent'

export default {
    name: 'CartPayment',
    components: {
        NavigationCart,
        // HeaderCart,
        LegalTextsComponent
    },
    data(){
        return {
            position: 3,
            loading: false
        }
    },
    mounted(){
      this.openPay();
    },
    methods: {
      async openPay(){
            this.loading = true;
            var _this = this;
            var response = await Payment.jsURL();
            if(response.data.success) {
              var jsurl = response.data.data.javascriptUrl;
              window.$.getScript(jsurl, function() {
                setTimeout(function (){
                  _this.loading = false;
                }, 4000);
                window.LightboxCheckoutHandler.startPayment(1, function(){
            		  console.error('An error occurred during the initialization of the payment lightbox.');
            	  });
              });
            }
        }
    }
}
</script>
