import { getHttp, postHttp } from './fetchApi';

export const Newsletter = {

    add(email, mailingList){
        var formData = new FormData();
        formData.append('action', 'campaign/forms/subscribe');
        formData.append('mailingList', mailingList);
        formData.append('email', email);
        
        return postHttp('/', formData);
    },


}
