<template>
    <div class="product p-3">
        <button @click="$goHistory(-2)" class="btn-icon-close"></button>
        <div class="product__content container-fluid" v-if="product">
            <div class="row">
                <div class="col-12 col-sm-6 product__content__image order-1 order-sm-0">
                    <div class="navigation-wrapper">
                        <div id="my-slider" className="keen-slider">
                            <picture v-for="image in product.images" v-bind:key="image" className="keen-slider__slide">
                                <img v-if="image.url" :src="image.url" :alt="title" :width="image.width" :height="image.height" />
                                <img v-else src="https://via.placeholder.com/630x900?text=Product+Image" :alt="product.title"/>
                            </picture>
                        </div>
                        <svg
                            v-if="product.images.length > 1"
                            @click="slider.prev()"
                            :class="{
                            arrow: true,
                            'arrow--left': true
                            }"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                            d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
                            ></path>
                        </svg>
                        <svg
                            v-if="product.images.length > 1"
                            @click="slider.next()"
                            :class="{
                            arrow: true,
                            'arrow--right': true
                            }"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
                        </svg>
                    </div>
                    <div class="product__content__image__details">
                        <div>
                            <span class="product__content__image__details__size" itemprop="size" :content="product.size">{{ `${product.size}` }}</span>
                            <span class="product__content__image__details__price" itemprop="price" :content="product.price">CHF {{ product.price }}</span>
                        </div>
                        <ButtonAddCart :product="product" />
                    </div>
                </div>
                <div class="col-12 col-sm-6 product__content__info order-0 order-sm-1">
                    <div class="product__content__info__reference text-xl" itemprop="reference" :content="product.ref">{{ product.ref }}</div>
                    <h2>{{ product.title }}</h2>
                    <div class="d-flex justify-content-between text-sm product__content__info__tags">
                        <span v-if="product.leftLabel">{{ product.leftLabel }}</span>
                        <span v-else>{{ product.customer[0].title }}</span>
                        <span v-if="product.rightLabel">{{ product.rightLabel }}</span>
                        <span v-else>{{ product.topic[0].title }}</span>
                    </div>
                    <!-- <div class="product__content__info__description d-none d-sm-block display-1" v-html="product.description"></div> -->
                    <div class="product__content__info__application d-none d-sm-block text-sm" v-html="product.application"></div>
                    <div class="product__content__info__quote d-none d-sm-block" v-html="product.quote"></div>
                    <div class="product__content__info__ingredients d-none d-sm-block text-sm" v-html="product.ingredients"></div>
                </div>
                <div class="col-12 product__content__info d-block d-sm-none order-2 mt-5">
                    <!-- <div class="product__content__info__description" v-html="product.description"></div> -->
                    <div class="product__content__info__application text-sm" v-html="product.application"></div>
                    <div class="product__content__info__quote" v-html="product.quote"></div>
                    <div class="product__content__info__ingredients text-sm" v-html="product.ingredients"></div>

                </div>
            </div>
            <div class="productsRelated" v-if="product.productsRelated.length">
                <div class="text-center text-base mb-3">{{ $t('related.title') }}</div>
                <div class="row justify-content-center">
                    <ProductPreview :product="product" v-for="product in product.productsRelated" v-bind:key="product" />
                </div>
            </div>
        </div>
        <p class="text-center loading" v-else>{{ $t("common.loading") }}</p>

    </div>
</template>

<script>
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { Products } from '@/http/Products';
import ButtonAddCart from '@/components/shop/ButtonAddCartComponent'
import ProductPreview from '@/components/shop/ProductPreviewComponent'

export default {
    name: 'Product',
    components: {
        ButtonAddCart,
        ProductPreview,
    },
    props: {
        slug: String
    },
    data(){
        return {
            product: null,
            slider: null
        }
    },
    watch: {
        '$route.params': {
            handler(newValue) {
                window.scrollTo(0, 0);
                this.getProduct(newValue.slug);
                // window.$(".shop").animate({scrollTop: '0px'}, 300);
            }
        }
    },
    updated() {
        window.$(".postPreview").hide();
        if(this.product.postsRelated.length > 0){
            for(var productID of this.product.postsRelated){
                window.$(`.postPreview.postPreview__${productID}`).show();
            }
        }else{
            window.$(".postPreview").show();
        }
    },
    mounted(){
        window.scrollTo(0, 0);
        this.getProduct(this.slug);
        this.$store.commit('general/setShowFilter', false)
        this.getProduct();
        this.$globalData.setHeaderData({
            title: '',
        });
    },
    unmounted() {
       window.$(".postPreview").show();
    },
    methods: {
        async getProduct(slug){
            try{
                var response = await Products.getProduct(slug);
                this.product = response.data;
                this.initSlider();
                this.$globalData.setHeaderData({
                    title: this.product.title,
                    description: this.product.description,
                });
                this.$store.commit('cart/setToken', this.product.meta.token);
            }catch{/**/}
        },
        initSlider(){
            var _this = this;
            setTimeout(() => {
                _this.slider = new KeenSlider('#my-slider',
                {
                    loop: true,
                    created: () => {
                
                    },
                },
                []);
            });
        }
    }
}
</script>
