<template>
    <button class="btn-addCart" @click="addCart()" :disabled="loading">
        <span class="text">{{ $t("product.buy") }}</span>
        <span class="preload">{{ $t("common.loading") }}</span>
    </button>
</template>

<script>

export default {
    name: 'ButtonAddCart',
    props: ['product'],
    computed: {
    },
    data(){
        return {
            loading: false
        }
    },
    mounted(){
    },
    methods: {
        async addCart(){
            var _this = this;
            this.loading = true;
            try{
                await this.$store.dispatch('cart/add', this.product.purchasableId);
                this.loading = false;
                this.$toast.show(this.$t("cart.add.success"), { duration: 1500, onClick: function(){
                    _this.$push('shop/cart');
                } });
            }catch (e) {
                this.loading = false;
                this.$toast.error(e.response.data.error, { duration: 2500 });
            }

        }
    }
}
</script>
