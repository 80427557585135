<template>
    <div class="legalTexts" v-if="$store.getters['general/get']">
        <div @click="showDelivery = !showDelivery" class="legalTexts__title"><button :class="{'btn-icon-open': !showDelivery, 'btn-icon-close': showDelivery}"></button><span>{{ $t('legal.delivery') }}</span></div>
        <div v-if="showDelivery" class="legalTexts__text" v-html="$store.getters['general/get'].delivery"></div>

        <div @click="showReturns = !showReturns" class="legalTexts__title"><button :class="{'btn-icon-open': !showReturns, 'btn-icon-close': showReturns}"></button><span>{{ $t('legal.returns') }}</span></div>
        <div v-if="showReturns" class="legalTexts__text" v-html="$store.getters['general/get'].returns"></div>

        <div @click="showPrivacy = !showPrivacy" class="legalTexts__title"><button :class="{'btn-icon-open': !showPrivacy, 'btn-icon-close': showPrivacy}"></button><span>{{ $t('legal.privacy') }}</span></div>
        <div v-if="showPrivacy" class="legalTexts__text" v-html="$store.getters['general/get'].privacyPolice"></div>

        <div @click="showAgb = !showAgb" class="legalTexts__title"><button :class="{'btn-icon-open': !showAgb, 'btn-icon-close': showAgb}"></button><span>AGB</span></div>
        <div v-if="showAgb" class="legalTexts__text" v-html="$store.getters['general/get'].agb"></div>
    </div>
</template>

<script>
export default {
    name: 'LegalTexts',
    computed: {
    },
    data(){
        return {
            showDelivery: false,
            showReturns: false,
            showPrivacy: false,
            showAgb: false
        }
    },
    mounted(){
    },
    methods: {
        
    }
}
</script>
