export const address = {
    namespaced: true,
    state: {
        data: {
            address: {},
            order: {}
        }
    },
    getters: {
        get: state => {
            return state.data
        },

    },
    mutations: {
        set: (state, data) => {
            state.data = data;
        },
    },
}
