<template>
  <div class="blog container-col">
      <div class="row d-sm-flex">
          <div class="col" v-if="post">
                <div :id="post.type.handle" :class="[ `postPreview postPreview__${post.type.handle} postPreview__${post.id} col-md-12`]">
                    <button class="btn-icon-left" @click="$push(`blog`)"></button>
                    <component :is="post.type.handle" :post="post"></component>
                </div>
          </div>
      </div>
  </div>
</template>

<script>
import { Posts } from '@/http/Posts';

// @ is an alias to /src
import  bonmot  from '@/components/blog/types/bonmotComponent';
import  dictionary  from '@/components/blog/types/dictionaryComponent';
import  facts  from '@/components/blog/types/factsComponent';
import  faq  from '@/components/blog/types/faqComponent';
import  howTo  from '@/components/blog/types/howToComponent';
import  interview  from '@/components/blog/types/interviewComponent';

export default {
    name: 'Post',
    components: {
        faq,
        bonmot,
        dictionary,
        facts,
        howTo,
        interview
    },
    data(){
        return {
            post: null
        }
    },
    mounted(){
        this.$store.dispatch('general/fetch');
    },
    created(){
        this.getPost();
    },
    methods: {
        async getPost(){
            try{
                this.loading = true;
                var response = await Posts.get(this.$route.params.slug);
                this.post = (response.data.data.length) ? response.data.data[0] : null;
                
                this.$globalData.setHeaderData({
                    title: this.post.title,
                    description: this.post.metaDescription,
                });

            }catch{/**/}
            this.loading = false;
        }
    }
}
</script>
