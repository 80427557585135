import { getHttp, postHttp } from './fetchApi';

export const Payment = {

    jsURL(){
        return getHttp('/actions/commerce-wallee/default/prepare-payment')
    },


}
