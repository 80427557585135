<template>
    <div class="productCart" :style="{'opacity': (loading) ? 0.4 : 1}">
        <div class="productCart__action order-3 order-sm-0">
            <span class="productCart__action__qty d-none d-sm-block" itemprop="quantity">{{ product.qty }} {{ $t('common.qty') }}</span>
            <button class="btn-icon-open" @click="addCart()" :disabled="loading"></button>
            <button class="btn-icon-remove" @click="removeQtyCart(product.qty-1)"  :disabled="loading"></button>
            <button class="btn-icon-close" @click="removeCart()" :disabled="loading"></button>
        </div>
        <div class="productCart__action order-1 d-block d-sm-none">
            <span class="productCart__action__qty" itemprop="quantity">{{ product.qty }} {{ $t('common.qty') }}</span>
        </div>
        <div class="productCart__details order-2 order-sm-1">
            <table>
                <tr>
                    <td>
                        <h2 class="productCart__details__title">
                            <router-link :to="$url(`shop/${productURL}`)" :title="product.description">{{ product.description }}</router-link>
                        </h2>
                        <span itemprop="reference" :content="product.ref" class="productCart__details__reference">{{ product.ref }}</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span class="productCart__details__size" itemprop="size" :content="product.weight">{{ `${product.weight}g` }}</span>
                        <span class="productCart__details__price" itemprop="price" :content="product.subtotal">{{ product.subtotalAsCurrency }}</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="productCart__image order-0 order-sm-2">
            <picture>
                <router-link :to="$url(`shop/${productURL}`)" :title="product.description">
                    <img v-if="productImage" :src="productImage.url" :alt="product.description"/>
                </router-link>
            </picture>
        </div>
    </div>
</template>

<script>
import { Products } from '@/http/Products';
export default {
    name: 'ProductCart',
    props: ['product'],
    components: {

    },
    data(){
        return {
            loading: false,
            productImage: null
        }
    },
    computed: {
        productURL: function(){
            try{
                return this.product.snapshot.product.slug
            }catch{
                return "";
            }
        }
    },
    mounted(){
        if(this.productURL){
            this.getProduct(this.productURL);
        }
    },
    methods: {
        async addCart(){
            this.loading = true;
            try{
                await this.$store.dispatch('cart/add', this.product.purchasableId);
                this.loading = false;
            }catch (e) {
                this.loading = false;
                this.$toast.error(e.response.data.error, { duration: 2500 });
            }
        },
        async removeQtyCart(qty){
            this.loading = true;
            try{
                await this.$store.dispatch('cart/update', { id: this.product.id, qty: qty });
                this.loading = false;
            }catch (e) {
                this.loading = false;
                this.$toast.error(e.response.data.error, { duration: 2500 });
            }
        },
        async removeCart(){
            this.loading = true;
            try{
                await this.$store.dispatch('cart/remove', this.product.id);
                this.loading = false;
            }catch (e) {
                this.loading = false;
                this.$toast.error(e.response.data.error, { duration: 2500 });
            }
        },
        async getProduct(slug){
            try{
                var response = await Products.getProduct(slug);
                this.productImage = response.data.image;
            }catch{/**/}
        }
    }
}
</script>
